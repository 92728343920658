<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 toolbar-title">
        <ion-buttons class="mr-2" slot="start">
          <ion-back-button
            color="primary"
            mode="ios"
            text=""
            :default-href="
              userType === 'b2b'
                ? `/b2b/order/purchase/order-detail/${orderId}`
                : `/sale/order/purchase/order-detail/${orderId}`
            "
            @click="goBack"
            :icon="chevronBackOutline"
          ></ion-back-button>
        </ion-buttons>
        <ion-label class="head-title">{{ $t('track_order') }} #{{ orderId }}</ion-label>
      </ion-toolbar>
      <div class="grey-lines" />
    </ion-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <!-- show pull refresh page when no internet connection -->
      <pull-refresh v-if="isConnectionIssue"></pull-refresh>
      <div :class="!isConnectionIssue ? 'bg-content' : ''">
        <!-- Skeleton loading -->
        <div v-if="showSkeletonLoading" class="column mx-3">
          <ion-skeleton-text
            class="my-2"
            animated
            style="width: 50%; height: 20px; border-radius: 6px"
          ></ion-skeleton-text>
          <ion-row class="ion-align-items-start mb-1">
            <ion-col class="mr-3 ion-no-padding" size="auto">
              <ion-skeleton-text
                animated
                style="width: 16px; height: 16px; border-radius: 100%"
              ></ion-skeleton-text>
            </ion-col>
            <ion-col class="ion-no-padding">
              <div class="column">
                <ion-skeleton-text
                  animated
                  style="width: 80%; height: 14px; border-radius: 4px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  animated
                  style="width: 50%; height: 14px; border-radius: 4px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  animated
                  style="width: 100%; height: 56px; border-radius: 10px"
                ></ion-skeleton-text>
              </div>
            </ion-col>
          </ion-row>
          <div v-for="(item, index) in 3" :key="index">
            <ion-row class="ion-align-items-start mb-1">
              <ion-col class="mr-3 ion-no-padding" size="auto">
                <ion-skeleton-text
                  animated
                  style="width: 16px; height: 16px; border-radius: 100%"
                ></ion-skeleton-text>
              </ion-col>
              <ion-col class="ion-no-padding">
                <div class="column">
                  <ion-skeleton-text
                    animated
                    style="width: 80%; height: 14px; border-radius: 4px"
                  ></ion-skeleton-text>
                  <ion-skeleton-text
                    animated
                    style="width: 50%; height: 14px; border-radius: 4px"
                  ></ion-skeleton-text>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <div v-else class="bg-white">
          <div v-if="orderStatusLogs && !isOrderIdNotFound && !isConnectionIssue">
            <order-status-cheap :orderStatusDetail="orderStatusLogs" :parent="'trackOrder'" />
            <div class="mb-2"></div>
            <!-- Order status logs info -->
            <div v-for="(item, index) in orderStatusLogs" :key="index">
              <ion-row>
                <ion-col size="auto" class="mr-2 column center-items">
                  <div v-show="index === 0" class="time-line" :class="timeLineDot(item.status)" />
                  <div
                    v-show="orderStatusLogs?.length > 1 && index === 0"
                    class="track-line-on"
                    :class="timeLine(item.status)"
                  />

                  <!-- grey or of timeline dot -->
                  <div v-show="index > 0" class="time-line time-line-grey" />
                  <div v-show="index > 0 && index !== orderStatusLogs?.length - 1" class="track-line-off" />
                </ion-col>

                <ion-col class="column">
                  <ion-text
                    class="mb-1 mini-info"
                    :class="index === 0 ? orderInfoColor(item.status) : 'title-grey'"
                    >{{ orderStatusInfo(item) }}</ion-text
                  >
                  <ion-text class="text-grey mini-info">{{ lastUpdateDateTime(item.created_at) }} </ion-text>
                  <!-- order status reason or note -->
                  <div
                    v-if="showOrderStatusNoteReason(item)"
                    class="px-2 py-2 po-number-and-note-reason mt-2"
                  >
                    <div class="column">
                      <ion-text class="po-text mb-1 mini-info"> {{ titleNoteReason(item.status) }}</ion-text>
                      <ion-text class="mini-info"> {{ item?.reason }}</ion-text>
                    </div>
                  </div>
                  <!-- View driver map location button -->
                  <ion-item
                    v-if="showDriverMapLocation(item.status) && index === 0"
                    class="view-driver-location-btn"
                    button
                    detail="false"
                    lines="none"
                    @click="setTrackDriverLocation(true, item.tracking_link)"
                  >
                    <ion-icon
                      class="mr-2"
                      style="width: 24px; height: 24px"
                      :icon="'/assets/images/map-location.svg'"
                    />
                    <ion-text class="text-primary">{{ $t('view_driver_location') }}</ion-text>
                  </ion-item>

                  <!-- View image button -->
                  <ion-button
                    v-if="item.images?.length > 0"
                    class="mt-3 track-order-btn"
                    expand="block"
                    fill="clear"
                    @click="setOpenImages(true, item.images)"
                  >
                    <ion-icon class="mr-2" style="width: 24px; height: 24px" :icon="eyeOutline" />
                    {{ $t('view_image') }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </div>
          </div>
          <!-- show not found order -->
          <connection-and-order-not-found
            v-if="isOrderIdNotFound"
            :isOrderIdNotFound="isOrderIdNotFound"
            :userType="userType"
          />
        </div>
      </div>
    </ion-content>

    <!-- Track driver location -->
    <ion-modal :is-open="isOpenDriverLocation" :backdropDismiss="false" @didDismiss="closeTrackDriverPage">
      <track-driver-location
        :trackingLink="trackingLinkUrl"
        :userType="userType"
        :orderId="orderId"
        @close-page="closeTrackDriverPage"
      ></track-driver-location>
    </ion-modal>

    <!-- Preview images -->
    <ion-modal :is-open="isOpenPreviewImages" :backdropDismiss="false" @didDismiss="setOpenImages(false, [])">
      <preview-images
        :images="imagesData"
        :userType="userType"
        @close-page="setOpenImages(false, [])"
      ></preview-images>
    </ion-modal>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { ACTION_BY_MERCHANT, MERCHANT_APP_NAME, ORDER_STATUS_NAME } from '@/modules/shared/constants';
import { getOrderStatusLogs } from '@/modules/shared/services/graphql';
import { useBackButton } from '@ionic/vue';
import dayjs from 'dayjs';
import { chevronBackOutline, eyeOutline } from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    // components
    OrderStatusCheap: defineAsyncComponent(() => import('./components/OrderStatusCheap.vue')),
    ConnectionAndOrderNotFound: defineAsyncComponent(() =>
      import('./components/ConnectionIssueAndOrderNotFound.vue')
    ),
    TrackDriverLocation: defineAsyncComponent(() => import('./TrackDriverLocation.vue')),
    PreviewImages: defineAsyncComponent(() => import('./components/PreviewImage.vue')),
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const orderStatusLogs = ref(null);
    const showSkeletonLoading = ref(true);
    const setSkeletonloading = (state) => (showSkeletonLoading.value = state);
    const orderId = ref('');
    const userType = ref('');
    const isConnectionIssue = ref(false);
    const isOrderIdNotFound = ref(false);
    const isOpenDriverLocation = ref(false);
    const trackingLinkUrl = ref('');
    const setTrackDriverLocation = (state, url) => {
      isOpenDriverLocation.value = state;
      trackingLinkUrl.value = url;
      if (state) {
        isBackFromTrackDriver.value = false;
      }
    };
    const isOpenPreviewImages = ref(false);
    const imagesData = ref([]);
    const setOpenImages = (state, images) => (
      (isOpenPreviewImages.value = state), (imagesData.value = images)
    );

    const isBackFromTrackDriver = ref(false);
    const clearIframeHistoryStateEffect = () => {
      isBackFromTrackDriver.value = false;
      // the <iframe> used inside TrackDriverLocation.vue is effected the history state back and add 1 more state history
      // so now need to use back with 2 step to clear it
      router.go(-2);
    };
    const closeTrackDriverPage = () => {
      setTrackDriverLocation(false, '');
      isBackFromTrackDriver.value = true;
    };

    const goBack = () => {
      if (isBackFromTrackDriver.value) {
        clearIframeHistoryStateEffect();
      } else {
        router.back();
      }
    };

    // handle when user use hardware back button
    useBackButton(10, () => {
      if (isOpenDriverLocation.value) {
        closeTrackDriverPage();
      } else if (isOpenPreviewImages.value) {
        setOpenImages(false, []);
      } else {
        goBack();
      }
    });

    const getOrderLogsData = async (orderId) => {
      setSkeletonloading(true);
      const currentPathUrl = window.location.pathname;
      const pathSegments = currentPathUrl.split('/');
      userType.value = pathSegments[1];

      try {
        const orderIdNumber = parseInt(orderId);
        const { data, errors } = await apolloClient.query({
          query: getOrderStatusLogs,
          variables: {
            orderId: orderIdNumber
          }
        });

        if (errors && errors.length > 0) {
          // when order is not found
          const errorMessage = errors[0].message;
          isOrderIdNotFound.value = errorMessage === 'Order not found';
        } else {
          orderStatusLogs.value = data.getOrderStatusLogs;
        }
      } catch (error) {
        isConnectionIssue.value =
          error.message === t('load_failed') || error.message === t('failed_to_fetch');
      } finally {
        setSkeletonloading(false);
      }
    };

    const handleRefresh = async (event) => {
      isConnectionIssue.value = false;
      setSkeletonloading(true);
      await getOrderLogsData(orderId.value);
      setSkeletonloading(false);
      event.target.complete();
    };

    const timeLineDot = (orderStatus) => {
      const redStatuses = [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY];
      return redStatuses.includes(orderStatus) ? 'time-line-red' : 'time-line-green';
    };

    const timeLine = (orderStatus) => {
      const redStatuses = [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY];
      return redStatuses.includes(orderStatus) ? 'red' : 'green';
    };

    const orderStatusInfo = (item) => {
      let orderStatus = item.status;
      let isCancelledBySupplier = item.app_name !== MERCHANT_APP_NAME;

      switch (true) {
        case orderStatus === ORDER_STATUS_NAME.CANCELLED && !isCancelledBySupplier:
          return t('has_been_cancelled');
        case orderStatus === ORDER_STATUS_NAME.COMPLETED:
          return t('order_has_been_completed');
        case orderStatus === ORDER_STATUS_NAME.PROCESSED:
          return item.changed_by + ' ' + t('is_preparing_order');
        case orderStatus === ORDER_STATUS_NAME.FAILED_DELIVERY:
          return t('delivery_failed');
        case orderStatus === ORDER_STATUS_NAME.DELIVERY_SUCCEED:
          return t('delivered_success');
        case orderStatus === ORDER_STATUS_NAME.DELIVERY_STARTED:
          return t('has_pickup_order');
        case orderStatus === ORDER_STATUS_NAME.CANCELLED && isCancelledBySupplier:
          return t('cancelled_by_supplier', {
            supplierName: item.changed_by
          });
        default:
          return t('receive_your_order');
      }
    };

    const orderInfoColor = (orderStatus) => {
      const redStatuses = [ORDER_STATUS_NAME.CANCELLED, ORDER_STATUS_NAME.FAILED_DELIVERY];
      return redStatuses.includes(orderStatus) ? 'text-red' : 'text-green';
    };

    const showOrderStatusNoteReason = (item) => {
      return (
        (['Delivery Succeed', ORDER_STATUS_NAME.FAILED_DELIVERY].includes(item.status) && !!item.reason) ||
        (item.status === ORDER_STATUS_NAME.CANCELLED && item.reason !== ACTION_BY_MERCHANT)
      );
    };

    const titleNoteReason = (orderStatus) => {
      switch (orderStatus) {
        case ORDER_STATUS_NAME.CANCELLED:
          return t('cancellation_reason');
        case ORDER_STATUS_NAME.FAILED_DELIVERY:
          return t('failure_reason');
        default:
          return t('note');
      }
    };

    const showDriverMapLocation = (orderStatus) => {
      return orderStatus === ORDER_STATUS_NAME.DELIVERY_STARTED;
    };

    const lastUpdateDateTime = (orderDateTimeUpdate) => {
      return dayjs(orderDateTimeUpdate).format('ddd, D MMMM YYYY hh:mm A');
    };
    return {
      chevronBackOutline,
      eyeOutline,
      route,
      router,
      showSkeletonLoading,
      setSkeletonloading,
      orderStatusLogs,
      handleRefresh,
      orderId,
      userType,
      isConnectionIssue,
      isOrderIdNotFound,
      trackingLinkUrl,
      isOpenDriverLocation,
      isOpenPreviewImages,
      imagesData,
      setTrackDriverLocation,
      setOpenImages,
      closeTrackDriverPage,
      isBackFromTrackDriver,
      clearIframeHistoryStateEffect,
      goBack,
      getOrderLogsData,
      timeLineDot,
      timeLine,
      orderStatusInfo,
      orderInfoColor,
      titleNoteReason,
      showDriverMapLocation,
      lastUpdateDateTime,
      showOrderStatusNoteReason
    };
  },

  async mounted() {
    this.setSkeletonloading(true);
    this.orderId = this.$route.params.order_id;
    const currentPathUrl = window.location.pathname;
    const pathSegments = currentPathUrl.split('/');
    this.userType = pathSegments[1];
    await this.getOrderLogsData(this.orderId);
  }
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
